import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {CartWizard} from './components/CartWizard'



const PaymentPage = () => (
  <Routes>
    <Route element={<Outlet />}>
      <Route
        path='cart-wizard'
        element={
          <>
            <CartWizard />
          </>
        }
      />
      <Route index element={<Navigate to='/payment/cart-wizard' />} />
    </Route>
  </Routes>
)

export default PaymentPage
