import { FC, useEffect, useState } from 'react'
import { TrailDetail, TrailDetailGroup } from '../../core/_models'
import { GroupItemWidget } from './GroupItemWidget'
import { orderBy, findIndex } from 'lodash'
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useTrailEdit } from '../core/TrailEditProvider';

type Props = {
    trail: TrailDetail,
    groupsId: Array<number>;
    changeGroups: (groups: Array<number>) => void;
}



const GroupWidget: FC<Props> = ({ trail, groupsId, changeGroups }) => {

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const trailGroups = trail.groups!

    const handleDragEnd = (event: any) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            const oldIndex = findIndex(groupsId, id => id == active.id);
            const newIndex = findIndex(groupsId, id => id == over.id);

            changeGroups(arrayMove(groupsId, oldIndex, newIndex))
        }
    }


    return (
        <>
            <div className="card card-flush pt-3 mb-5 mb-lg-10" data-kt-subscriptions-form="pricing">

                <div className="card-header">

                    <div className="card-title">
                        <h2 className="fw-bold">Timeline da Trilha</h2>
                    </div>


                    <div className="card-toolbar">
                        <a href="#" className="btn btn-primary">Adicionar Grupo</a>
                    </div>

                </div>


                <div className="card-body pt-0">

                    <div>
                        <DndContext
                            sensors={sensors}
                            collisionDetection={closestCenter}
                            onDragEnd={handleDragEnd}
                        >
                            <SortableContext
                                items={groupsId}
                                strategy={verticalListSortingStrategy}
                            >
                                {trailGroups.map((group, index) => <GroupItemWidget group={group} withSeparator={index < trailGroups.length - 1} key={group.id} />)}
                            </SortableContext>
                        </DndContext>
                    </div>

                </div>

            </div>
        </>


    )
}


export { GroupWidget }
