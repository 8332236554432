import { Outlet, Route, Routes, useParams } from "react-router-dom";


import { PageLink, PageTitle } from "../../../_metronic/layout/core";

import { useEffect } from "react";
import { TrailListWrapper } from "./trail/trail-list/TrailList";
import TrailEditPage from "./trail/trail-list/trail-edit/TrailEditPage";
import { TrailModalProvider } from "./trail/trail-list/core/TrailModalProvider";
import { TrailProvider } from "./trail/trail-list/core/TrailResponseProvider";


const breadCrumbs: Array<PageLink> = [
  {
    title: "Manager",
    path: "/manager/",
    isSeparator: false,
    isActive: false,
  },
];

const ManagerPage = () => {

  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>Engenharia de Dados</PageTitle>

    </>
  );
};



const ManagerWrapper = () => {

  useEffect(() => {
    document.body.classList.remove('aside-secondary-disabled')
    document.body.classList.add('aside-secondary-enabled')

    return () => {
      document.body.classList.add('aside-secondary-disabled')
      document.body.classList.remove('aside-secondary-enabled')
    };
  }, []);

  return (
    <Routes>
      <Route element={<Outlet />}>
      <Route path="trails" element={<TrailListWrapper />} />
      <Route path="trails/:slug" element={<TrailEditPage />} />

        {/* <Route path="/register/courses" element={<QuizPage />} /> */}
      </Route>
      <Route index element={<ManagerPage />} />
    </Routes>

  );
};

export default ManagerWrapper;
