import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {Trail, TrailQueryResponse, TrailDetailResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const TRAIL_URL = `${API_URL}/learning/trails`

const getTrails = (): Promise<TrailQueryResponse> => {
  return axios
    .get(`${TRAIL_URL}`)
    .then((d: AxiosResponse<TrailQueryResponse>) => d.data)
}

const getTrailBySlug = (slug: string): Promise<TrailDetailResponse> => {
  return axios
    .get(`${TRAIL_URL}/${slug}/details`)
    .then((d: AxiosResponse<TrailDetailResponse>) => d.data)
}


export {getTrails, getTrailBySlug}
