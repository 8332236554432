import {FC, useState, createContext, useContext, useMemo, useEffect, useCallback, Dispatch, SetStateAction} from 'react'
import {
  ID,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  initialListView,
  ListViewContextProps,
  groupingOnSelectAll,
  WithChildren,
} from '../../../../../../_metronic/helpers'
import {useTrail} from './TrailResponseProvider'
import { TrailDetail } from './_models'

import {getTrailBySlug} from './_requests'


export type TrailModalContextProps = {
  currentTrailDetail: TrailDetail | undefined;
  // groupsId: Array<number>;
  loadTrailBySlug: (slug: string) => void
  // changeGroups: (groups: Array<number>) => void;
  onClose: () => void
  loading: boolean;
  error: any;
}

export const initialTrailContext: TrailModalContextProps = {
  currentTrailDetail: undefined,
  // groupsId: [],
  loadTrailBySlug: () => { },
  // changeGroups: () => { },
  onClose: () => { },
  loading: false,
  error: ''
}


const TrailModalContext = createContext<TrailModalContextProps>(initialTrailContext)

const TrailModalProvider: FC<WithChildren> = ({children}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [currentTrailDetail, setCurrentTrailDetail] = useState<TrailDetail | any>(undefined);
  // const [groupsId, setGroupsId] = useState<Array<number> | any>(undefined)

  const loadTrailBySlug = useCallback(async (alug: string) => {
    try {
      console.log('loadTrailBySlug')
      setLoading(true);

      try {
        const dataResponse = await getTrailBySlug(alug);
        setCurrentTrailDetail(dataResponse)
      } catch (error) {
        console.error('Falha ao inicializar os dados da trilha', error);
        setError('Falha ao inicializar os dados do trilha');
        setLoading(false);
      }

      setLoading(false);

    } catch (error) {
      console.error('Falha ao inicializar os dados da trilha', error);
      setError('Falha ao inicializar os dados do trilha');
      setLoading(false);
    }
  }, []);

  // const changeGroups = (groups: Array<number>) => {
  //   // setGroupsId(groups)
  // };

  return (
    <TrailModalContext.Provider
      value={{
        currentTrailDetail,
        // groupsId,
        loading,
        error,
        // changeGroups,
        loadTrailBySlug,
        onClose: () => {
          // setCurrentTrailDetail(undefined)
        },
      }}
    >
      {children}
    </TrailModalContext.Provider>
  )
}

const useTrailModal = () => useContext(TrailModalContext)

export {TrailModalProvider, useTrailModal}
