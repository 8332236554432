import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { TrailDetail } from './_models';
import { getTrailBySlug } from './_requests'
import { WithChildren } from '../../../../../../_metronic/helpers';

interface TrailManagerContextValue {
    selectedTrail: TrailDetail;
    loading: boolean;
    error: string;
    onSelectedTrail: (slug: string) => void
}

const TrailManagerContext = createContext<TrailManagerContextValue | undefined>(undefined);

export const TrailManagerProvider: React.FC<WithChildren> = ({ children }) => {
    const [selectedTrail, setSelectedTrail] = useState<TrailDetail | any>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const onSelectedTrail = useCallback(async (slug: string) => {
        try {
            setLoading(true);
            await getTrailBySlug(slug).then(dataResponse => {
                setSelectedTrail(dataResponse)
                setLoading(false);
            });
        } catch (error) {
            console.error('Falha ao inicializar os dados do curso', error);
            setError('Falha ao inicializar os dados do curso');
            setLoading(false);
        }
    }, []);

    return (
        <TrailManagerContext.Provider
            value={{
                selectedTrail,
                error,
                loading,
                onSelectedTrail
            }}>
            {children}
        </TrailManagerContext.Provider>
    );
};

export const useTrailManager = () => {
    const context = useContext(TrailManagerContext);
    if (!context) {
        throw new Error('useTrailManager must be used within an TrailManagerProvider');
    }
    return context;
};
