import { jwtDecode } from "jwt-decode";
import { createContext, Dispatch, FC, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';

import { WithChildren } from '../../../../_metronic/helpers';
import { LayoutSplashScreen } from '../../../../_metronic/layout/core';
import { AuthModel, UserAuthModel } from './_models';
import { requestRefreshToken } from './_requests';
import * as authHelper from './AuthHelpers';

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserAuthModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserAuthModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserAuthModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser, saveAuth} = useAuth()
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  const refreshToken = useCallback(async (token) => {
    try {
      const { data } = await requestRefreshToken(token);
      if (auth) {
        await saveAuth({...data, user: auth.user});
        setCurrentUser(auth.user);
        setShowSplashScreen(false);
      }
    } catch (error) {
      logout()
      setShowSplashScreen(false)
    }
  }, [auth, saveAuth, setCurrentUser, logout]);

  useEffect(() => {
    if (auth && auth.token) {
      const decode = jwtDecode(auth.token);
      const expDate = decode.exp && new Date(decode.exp * 1000);

      if (expDate && new Date() < expDate) {
        setCurrentUser(auth.user)
        setShowSplashScreen(false)
      } else {
        refreshToken(auth.refreshToken);
      }
      
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthInit, AuthProvider, useAuth };

