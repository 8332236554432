/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {Trail} from '../../core/_models'

type Props = {
  trail: Trail
}

const TrailNameCell: FC<Props> = ({trail}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
      <a href='#'>
        {trail.shortImage ? (
          <div className='symbol-label'>
            <img src={toAbsoluteUrl(`${trail.shortImage}`)} alt={trail.name} className='w-100' />
          </div>
        ) : null
        }
      </a>
    </div>
    <div className='d-flex flex-column'>
      <a href='#' className='text-gray-800 text-hover-primary mb-1'>
        {trail.name}
      </a>
    </div>
  </div>
)

export {TrailNameCell}
