import {FC} from 'react'

type Props = {
    isDeleted: boolean;
    isComingSoon: boolean
}

const TrailStatusColumn: FC<Props> = ({ isDeleted, isComingSoon }) => {
    return (
        <>
          { isDeleted && <div className='badge badge-danger fw-bolder'>Excluído</div>}
          { !isDeleted && isComingSoon && <div className='badge badge-success fw-bolder'>Em Breve</div>}
          { !isDeleted && !isComingSoon && <div className='badge badge-primary fw-bolder'>Ativo</div>}
        </>  
      
        
      )
}

export {TrailStatusColumn}
