import { useEffect, useRef, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Step1 } from './steps/Step1'
import { Step2 } from './steps/Step2'
import { Step3 } from './steps/Step3'
import { Step4 } from './steps/Step4'
import { Step5 } from './steps/Step5'
import { StepperComponent } from '../../../../_metronic/assets/ts/components'
import { Form, Formik, FormikValues } from 'formik'
import { createAccountSchemas, ICreateAccount, inits } from './CreateAccountWizardHelper'
import CartSummary from './CartSummary';

const CartWizard = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues] = useState<ICreateAccount>(inits)
  const [isSubmitButton, setSubmitButton] = useState(false)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totalStepsNumber)
  }

  const submitStep = (values: ICreateAccount, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }

    if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
      stepper.current.goNext()
    } else {
      stepper.current.goto(1)
      actions.resetForm()
    }

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totalStepsNumber)

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <div className="d-flex flex-column flex-column-fluid">
      <div className="app-toolbar py-3 py-lg-6">
        <div className='d-flex d-flex-column justify-content-center '>
          <img alt='Aprende Man'
          src={toAbsoluteUrl("/media/logos/aprendeman-logo-full.svg")}
          className="h-70px me-3"
          />
        </div>

      </div>
      <div className="app-content flex-column-fluid">
        <div className="app-container container-xxl">
          <div className='d-flex flex-column flex-lg-row'>
            <div className='flex-lg-row-fluid me-lg-15 order-2 order-lg-1 mb-10 mb-lg-0'>
              <div className='card card-flush pt-3 mb-5 mb-xl-10'>
                <div className='card-body'>
                  <div
                    ref={stepperRef}
                    className='stepper stepper-links d-flex flex-column pt-15'
                    id='kt_create_account_stepper'
                  >
                    <div className='stepper-nav mb-5'>
                      <div className='stepper-item current' data-kt-stepper-element='nav'>
                        <h3 className='stepper-title'>Account Type</h3>
                      </div>

                      <div className='stepper-item' data-kt-stepper-element='nav'>
                        <h3 className='stepper-title'>Account Info</h3>
                      </div>

                      <div className='stepper-item' data-kt-stepper-element='nav'>
                        <h3 className='stepper-title'>Business Info</h3>
                      </div>

                      <div className='stepper-item' data-kt-stepper-element='nav'>
                        <h3 className='stepper-title'>Billing Details</h3>
                      </div>

                      <div className='stepper-item' data-kt-stepper-element='nav'>
                        <h3 className='stepper-title'>Completed</h3>
                      </div>
                    </div>

                    <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
                      {() => (
                        <Form className='mx-auto mw-600px w-100 pt-15 pb-10' id='kt_create_account_form'>
                          <div className='current' data-kt-stepper-element='content'>
                            <Step1 />
                          </div>

                          <div data-kt-stepper-element='content'>
                            <Step2 />
                          </div>

                          <div data-kt-stepper-element='content'>
                            <Step3 />
                          </div>

                          <div data-kt-stepper-element='content'>
                            <Step4 />
                          </div>

                          <div data-kt-stepper-element='content'>
                            <Step5 />
                          </div>

                          <div className='d-flex flex-stack pt-15'>
                            <div className='mr-2'>
                              <button
                                onClick={prevStep}
                                type='button'
                                className='btn btn-lg btn-light-primary me-3'
                                data-kt-stepper-action='previous'
                              >
                                <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                                Back
                              </button>
                            </div>

                            <div>
                              <button type='submit' className='btn btn-lg btn-primary me-3'>
                                <span className='indicator-label'>
                                  {!isSubmitButton && 'Continue'}
                                  {isSubmitButton && 'Submit'}
                                  <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                                </span>
                              </button>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex-column flex-lg-row-auto w-lg-250px w-xl-300px mb-10 order-1 order-lg-2'>
              <CartSummary />
            </div>
          </div>
          
        </div>
      </div>
    </div>



  )
}

export { CartWizard }
