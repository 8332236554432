import { Outlet, Route, Routes, useParams } from "react-router-dom";


import { PageLink, PageTitle } from "../../../../../../_metronic/layout/core";

import { useEffect } from "react";
import { TrailModalProvider, useTrailModal } from "../core/TrailModalProvider";
import { TrailProvider } from "../core/TrailResponseProvider";
import { TrailWidget } from "./components/TrailWidget";
import { GroupWidget } from "./components/GroupWidget";
import { TrailManagerProvider, useTrailManager } from "../core/TrailManagerProvider";



const breadCrumbs: Array<PageLink> = [
    {
        title: "Trilhas",
        path: "/manager/trails",
        isSeparator: false,
        isActive: false,
    },
];



const TrailEditPage = () => {

    const { slug } = useParams();
    const { selectedTrail, onSelectedTrail } = useTrailManager()
    useEffect(() => {
        if (slug) {
            onSelectedTrail(slug);
        }
    }, [slug, onSelectedTrail])

    useEffect(() => {
        console.log(selectedTrail)
    }, [selectedTrail])
 

    useEffect(() => {
        document.body.classList.add('aside-secondary-disabled')
        document.body.classList.remove('aside-secondary-enabled')
    }, []);

    return (
        <>
            <PageTitle breadcrumbs={breadCrumbs}>{selectedTrail?.name}</PageTitle>
            <div id="kt_app_content" className="app-content flex-column-fluid">

                <div id="kt_app_content_container" className="app-container container-xxl">

                    <div className="d-flex flex-column flex-lg-row">

                        <div className="flex-lg-row-fluid me-lg-15 order-2 order-lg-1 mb-10 mb-lg-0">

                            <form className="form" action="#" id="kt_subscriptions_create_new">
                                {selectedTrail && <TrailWidget trail={selectedTrail!} />}

                                {selectedTrail && <GroupWidget groupsId={[]} changeGroups={() => { }} trail={selectedTrail!} />}

                                <div className="card card-flush pt-3 mb-5 mb-lg-10">

                                    <div className="card-header">

                                        <div className="card-title">
                                            <h2 className="fw-bold">Products</h2>
                                        </div>


                                        <div className="card-toolbar">
                                            <button type="button" className="btn btn-light-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_add_product">Add Product</button>
                                        </div>

                                    </div>


                                    <div className="card-body pt-0">

                                        <div className="table-responsive">

                                            <table className="table align-middle table-row-dashed fs-6 fw-semibold gy-4" id="kt_subscription_products_table">
                                                <thead>
                                                    <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                                                        <th className="min-w-300px">Product</th>
                                                        <th className="min-w-100px">Qty</th>
                                                        <th className="min-w-150px">Total</th>
                                                        <th className="min-w-70px text-end">Remove</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="text-gray-600">
                                                    <tr>
                                                        <td>Beginner Plan</td>
                                                        <td>1</td>
                                                        <td>149.99 / Month</td>
                                                        <td className="text-end">

                                                            <a href="#" className="btn btn-icon btn-flex btn-active-light-primary w-30px h-30px me-3" data-bs-toggle="tooltip" title="Delete" data-kt-action="product_remove">
                                                                <i className="ki-duotone ki-trash fs-3">
                                                                    <span className="path1"></span>
                                                                    <span className="path2"></span>
                                                                    <span className="path3"></span>
                                                                    <span className="path4"></span>
                                                                    <span className="path5"></span>
                                                                </i>
                                                            </a>

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Pro Plan</td>
                                                        <td>1</td>
                                                        <td>499.99 / Month</td>
                                                        <td className="text-end">

                                                            <a href="#" className="btn btn-icon btn-flex btn-active-light-primary w-30px h-30px me-3" data-bs-toggle="tooltip" title="Delete" data-kt-action="product_remove">
                                                                <i className="ki-duotone ki-trash fs-3">
                                                                    <span className="path1"></span>
                                                                    <span className="path2"></span>
                                                                    <span className="path3"></span>
                                                                    <span className="path4"></span>
                                                                    <span className="path5"></span>
                                                                </i>
                                                            </a>

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Team Plan</td>
                                                        <td>1</td>
                                                        <td>999.99 / Month</td>
                                                        <td className="text-end">

                                                            <a href="#" className="btn btn-icon btn-flex btn-active-light-primary w-30px h-30px me-3" data-bs-toggle="tooltip" title="Delete" data-kt-action="product_remove">
                                                                <i className="ki-duotone ki-trash fs-3">
                                                                    <span className="path1"></span>
                                                                    <span className="path2"></span>
                                                                    <span className="path3"></span>
                                                                    <span className="path4"></span>
                                                                    <span className="path5"></span>
                                                                </i>
                                                            </a>

                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>

                                    </div>

                                </div>


                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};



const TrailEditPageWrapper = () => (
    <TrailManagerProvider>
        <TrailEditPage />
    </TrailManagerProvider>

)


export default TrailEditPageWrapper;
