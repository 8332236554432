/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import {
  KTIcon,
  WithChildren,
  toAbsoluteUrl,
} from "../../../../_metronic/helpers";
import { Field } from "formik";

type Props = {};

const CartSummary: FC<Props & WithChildren> = () => {
  return (
    <div
      className="card card-flush mb-0"
      data-kt-sticky="true"
      data-kt-sticky-name="subscription-summary"
      data-kt-sticky-offset="{default: false, lg: '200px'}"
      data-kt-sticky-width="{lg: '250px', xl: '300px'}"
      data-kt-sticky-left="auto"
      data-kt-sticky-top="150px"
      data-kt-sticky-animation="false"
      data-kt-sticky-zindex="95"
    >
      <div className="card-body pt-4 fs-6">
        <div className="mb-2">
          <div className="d-flex flex-row-fluid">
            <div className="d-flex flex-row-auto w-40px">
              <KTIcon iconName='cheque' className='fs-3x text-primary' />
            </div>


            <div className="d-flex flex-row-fluid flex-center">
              <span className="fs-4 fw-bold text-gray-900 me-2">
                Resumo
              </span>
            </div>
          </div>
        </div>

        <div className="separator separator-dashed mb-7"></div>

        <div className="mb-10">
          <h5 className="mb-4">Detalhe da Compra</h5>
          <table className="table fs-6 fw-semibold gs-0 gy-2 gx-2">
            <tbody>
              <tr className="">
                <td className="text-white-800">Plano Anual:</td>
                <td className="text-gray-800">R$ 1.497,00</td>
              </tr>
              <tr className="">
                <td className="text-gray-400">Desconto:</td>
                <td className="text-gray-800">R$ 0,00</td>
              </tr>
              <tr className="">
                <td className=""><h5>Total:</h5></td>
                <td className=""><h5>R$ 1.497,00</h5></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="separator separator-dashed mb-7"></div>

        <div className="mb-7">
          <h5 className="mb-4">Aplicar cupom de desconto</h5>

          <div className="py-2">
            <div className="d-flex flex-stack">
              <div className="d-flex">
                <KTIcon
                  iconName="messages"
                  className="me-4 fs-1"
                />

                <div className="d-flex flex-column">
                
                {/* <Field
                    type='text'
                    className='form-control form-control-solid'
                    placeholder=''
                    name='discont'
                  /> */}
                
                  
                </div>
              </div>

              <div className="d-flex justify-content-end">
                <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                  <span className="form-check-label fw-semibold text-muted"></span>
                </label>
              </div>
            </div>

            <div className="separator separator-dashed my-5"></div>

            <div className="d-flex flex-stack">
              <div className="d-flex">
                <KTIcon
                  iconName="message-question"
                  className="me-4 fs-1"
                />

                <div className="d-flex flex-column">
                  <a
                    href="#"
                    className="fs-5 text-dark text-hover-primary fw-bold"
                  >
                    Arretados
                  </a>
                  <div className="fs-6 fw-semibold text-muted">
                    Precisa falar com o time de arretados?
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-end">
                <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                  <span className="form-check-label fw-semibold text-muted"></span>
                </label>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  );
};

export default CartSummary;
