
import { QueryRequestProvider } from './core/TrailRequestProvider'

import { UsersListHeader } from './components/header/UsersListHeader'
import { TrailsTable } from './table/TrailsTable'
// import {UserEditModal} from './user-edit-modal/UserEditModal'
import { KTCard } from '../../../../../_metronic/helpers'
import { TrailProvider } from './core/TrailResponseProvider'
import { TrailModalProvider, useTrailModal } from './core/TrailModalProvider'
import { TrailEditModal } from './trail-edit-modal/TrailEditModal'
import { TrailEditProvider } from './trail-edit/core/TrailEditProvider'
import { TrailManagerProvider, useTrailManager } from './core/TrailManagerProvider'



const TrailList = () => {

  return (
    <>
      <KTCard>
        <UsersListHeader />
        <TrailsTable />
      </KTCard>

    </>
  )
}

const TrailListWrapper = () => (
  <QueryRequestProvider>
    <TrailProvider>
      <TrailList />
    </TrailProvider>
  </QueryRequestProvider>
)


export { TrailListWrapper }
