import axios from "axios";

import { AuthModel, AuthRefreshToken } from "./_models";

const API_URL = process.env.REACT_APP_API_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`;
export const LOGIN_URL = `${API_URL}/auth/email/login`;
export const REGISTER_URL = `${API_URL}/register`;
export const REFRESH_URL = `${API_URL}/auth/refresh`;
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot/password`;
export const REQUEST_RESET_PASSWORD_URL = `${API_URL}/auth/reset/password`;

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  });
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  });
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  });
}

export function requestResetPassword(password: string, hash: string) {
  return axios.post<{ result: boolean }>(REQUEST_RESET_PASSWORD_URL, {
    password,
    hash,
  });
}

export function requestRefreshToken(refreshToken: string) {
  const instance = axios.create({
    baseURL: API_URL,
    timeout: 1000,
    headers: { Authorization: `bearer ${refreshToken}` },
  });

  return instance.post<AuthRefreshToken>(REFRESH_URL);
}

// export function getUserByToken(token: string) {
//   return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
//     api_token: token,
//   })
// }
