/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { Dispatch, FC, SetStateAction } from "react";
import { useLocation } from "react-router";

import { AsideMenuItem } from "./AsideMenuItem";

const tabs: ReadonlyArray<{ link: string; icon: string; tooltip: string }> = [
  {
    link: "/dashboard",
    icon: "briefcase",
    tooltip: "Menu",
  },
  {
    link: "/trail",
    icon: "abstract-26",
    tooltip: "Trilha",
  },
  {
    link: "/crafted/pages",
    icon: "shield-tick",
    tooltip: "Tasks",
  },
  {
    link: "/apps/chat",
    icon: "chart-simple",
    tooltip: "Notifications",
  },
  {
    link: "/apps/user-management/users",
    icon: "add-files",
    tooltip: "Authors",
  },
];

type Props = {
  link: string;
  setLink: Dispatch<SetStateAction<string>>;
};

const AsideTabs: FC<Props> = ({ link, setLink }) => {
  const { pathname } = useLocation();

  return (
    <div
      className="hover-scroll-y mb-10"
      data-kt-scroll="true"
      data-kt-scroll-activate="{default: false, lg: true}"
      data-kt-scroll-height="auto"
      data-kt-scroll-wrappers="#kt_aside_nav"
      data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
      data-kt-scroll-offset="0px"
    >
      <div className="nav flex-column" id="kt_aside_nav_tabs">
        <AsideMenuItem to="/dashboard" icon="briefcase" iconNavLink={true}  />

        <AsideMenuItem
          to="/trail"
          icon="abstract-26" iconNavLink={true}
        />

        <AsideMenuItem to="/builder" icon="shield-tick" iconNavLink={true} />

        <AsideMenuItem to="crafted/pages/profile/*" icon="chart-simple" iconNavLink={true}  />

        <AsideMenuItem to="/manager/" icon="add-files" iconNavLink={true}  />
        
      </div>
    </div>
  );
};
export { AsideTabs };
