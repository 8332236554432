/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useCallback, createContext} from 'react'

import {
  WithChildren,
} from '../../../../../../_metronic/helpers'
import {getTrails as getTrailsRequest} from './_requests'
import {Trail} from './_models'

interface TrailContextValue {
  trails: Trail[];
  getTrails: () => Promise<any>;
  loading: boolean;
  error: any;
}

const TrailContext = createContext<TrailContextValue | undefined>(undefined);

export const TrailProvider: FC<WithChildren> = ({children}) => {
  const [trails, setTrails] = useState<Trail[] | any>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const getTrails = useCallback(async () => {
    try {
      setLoading(true);
      await getTrailsRequest().then(dataResponse => {
        setTrails(dataResponse)
        setLoading(false);
      });
    } catch (error) {
      console.error('Falha ao inicializar os dados da trilha', error);
      setError('Falha ao inicializar os dados do trilha');
      setLoading(false);
    }
  }, []);


  return (
    <TrailContext.Provider value={{trails, loading, getTrails, error}}>
      {children}
    </TrailContext.Provider>
  )
}

export const useTrail = () => {
  const context = useContext(TrailContext);
  if (!context) {
    throw new Error('useTrail must be used within an TrailProvider');
  }
  return context;
};