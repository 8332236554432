// @ts-nocheck
import {Column} from 'react-table'
import {TrailStatusColumn} from './TrailStatusCell'
import {UserCustomHeader} from './UserCustomHeader'
import {TrailNameCell} from './TrailNameCell'
import {TrailActionsCell} from './TrailActionsCell'
import {Trail} from '../../core/_models'


const trailsColumns: ReadonlyArray<Column<Trail>> = [
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Nome' className='min-w-200px' />,
    id: 'name',
    Cell: ({...props}) => <TrailNameCell trail={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Slug' className='min-w-125px' />,
    accessor: 'slug',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Descrição' className='min-w-125px' />
    ),

    accessor: 'description',
    // Cell: ({...props}) => <UserLastLoginCell last_login={props.data[props.row.index].last_login} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id: 'status',
    Cell: ({...props}) => <TrailStatusColumn isDeleted={props.data[props.row.index].isDeleted} isComingSoon={props.data[props.row.index].isComingSoon}/>,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <TrailActionsCell slug={props.data[props.row.index].slug} />,
  },
]

export {trailsColumns}
