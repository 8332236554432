import { FC } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../../../../../_metronic/helpers';
import { TrailDetailGroup } from '../../core/_models';
import { useSortable } from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

type Props = {
  withSeparator?: boolean;
  group: TrailDetailGroup;
}

const GroupItemWidget: FC<Props> = ({ withSeparator, group}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: group.id!});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <>
      <div className="py-1" ref={setNodeRef} style={style} {...attributes} {...listeners}>

        <div className="py-3 d-flex flex-stack flex-wrap">

          <div className="d-flex align-items-center collapsible toggle collapsed" data-bs-toggle="collapse" data-bs-target={`#detalhes-cursos-${group.id}`}>

            <div className="btn btn-sm btn-icon btn-active-color-primary ms-n3 me-2">
              <i className="ki-duotone ki-minus-square toggle-on text-primary fs-2">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
              <i className="ki-duotone ki-plus-square toggle-off fs-2">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
              </i>
            </div>

            <div className="me-3">
              <div className="d-flex align-items-center fw-bold">{group.name}
                <div className="badge badge-light-primary ms-5">Primary</div></div>
            </div>

          </div>


          <div className="d-flex my-3 ms-9">

            <label className="form-check form-check-custom form-check-solid me-5">
              {/* <input className="form-check-input" type="radio" name="payment_method" checked /> */}
            </label>

          </div>

        </div>


        <div id={`detalhes-cursos-${group.id}`} className="collapse fs-6 ps-10">

          <div className="d-flex flex-wrap py-5">

            <div className="flex-equal me-5">
              <table className="table table-flush fw-semibold gy-1">
                <tbody>
                  <tr>
                    <td className="text-muted min-w-125px w-125px">Name</td>
                    <td className="text-gray-800">Emma Smith</td>
                  </tr>
                  <tr>
                    <td className="text-muted min-w-125px w-125px">Number</td>
                    <td className="text-gray-800">**** 7972</td>
                  </tr>
                  <tr>
                    <td className="text-muted min-w-125px w-125px">Expires</td>
                    <td className="text-gray-800">12/2024</td>
                  </tr>
                  <tr>
                    <td className="text-muted min-w-125px w-125px">Type</td>
                    <td className="text-gray-800">Mastercard credit card</td>
                  </tr>
                  <tr>
                    <td className="text-muted min-w-125px w-125px">Issuer</td>
                    <td className="text-gray-800">VICBANK</td>
                  </tr>
                  <tr>
                    <td className="text-muted min-w-125px w-125px">ID</td>
                    <td className="text-gray-800">id_4325df90sdf8</td>
                  </tr>
                </tbody>
              </table>
            </div>


            <div className="flex-equal">
              <table className="table table-flush fw-semibold gy-1">
                <tbody>
                  <tr>
                    <td className="text-muted min-w-125px w-125px">Billing address</td>
                    <td className="text-gray-800">AU</td>
                  </tr>
                  <tr>
                    <td className="text-muted min-w-125px w-125px">Phone</td>
                    <td className="text-gray-800">No phone provided</td>
                  </tr>
                  <tr>
                    <td className="text-muted min-w-125px w-125px">Email</td>
                    <td className="text-gray-800">
                      <a href="#" className="text-gray-900 text-hover-primary">smith@kpmg.com</a>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-muted min-w-125px w-125px">Origin</td>
                    <td className="text-gray-800">Australia
                      <div className="symbol symbol-20px symbol-circle ms-2">
                        <img src="assets/media/flags/australia.svg" />
                      </div></td>
                  </tr>
                  <tr>
                    <td className="text-muted min-w-125px w-125px">CVC check</td>
                    <td className="text-gray-800">Passed
                      <i className="ki-duotone ki-check-circle fs-2 text-success">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i></td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>

        </div>

      </div>

      {withSeparator && <div className="separator separator-dashed"></div>}
    </>


  )
}

export { GroupItemWidget }
