import {FC} from 'react'
import clsx from 'clsx'
import {useLocation} from 'react-router'
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import {HeaderNotificationsMenu, HeaderUserMenu, QuickLinks} from '../../../partials'
import { checkIsActive, KTIcon, WithChildren, toAbsoluteUrl } from "../../../helpers";
import {useLayout} from '../../core'
import tooltip from 'bootstrap/js/dist/tooltip';

type Props = {
  to: string
  title?: string
  icon?: string
  iconNavLink?: boolean
  hasBullet?: boolean
  }

const AsideMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  iconNavLink,
  hasBullet = false,
  }) => {

  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {aside} = config

  return (
    <div className='menu-item'>
      
    <Link className={clsx(`menu-link without-sub ${iconNavLink ? 'nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light' : ''}`, {active: isActive})} to={to}>
      {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
      {icon && (
        <KTIcon iconName={icon} className="fs-2x" />
      )}
      {title && (
        <span className='menu-title'>{title}</span>  
      )}
      
    </Link>
    {children}
    </div>
  )
}

export {AsideMenuItem}
