import { FC } from 'react'
import { TrailDetail } from '../../core/_models'
import { toAbsoluteUrl } from '../../../../../../../_metronic/helpers'
import { TrailStatusColumn } from '../../table/columns/TrailStatusCell'

type Props = {
    trail: TrailDetail
}

const TrailWidget: FC<Props> = ({ trail }) => {

    const trailAvatarImg = toAbsoluteUrl(trail.shortImage!)

    return (
        <div className="card card-flush pt-3 mb-5 mb-lg-10">
            <div className="card-header">

                <div className="card-title">
                    <h2 className="fw-bold">{trail.name}</h2>
                    <div className="ms-5">
                        <TrailStatusColumn isComingSoon={trail.isComingSoon!} isDeleted={trail.isDeleted!} />
                    </div>
                </div>
                <div className="card-toolbar">
                    <button type="button" className="btn btn-primary">Editar Trilha</button>
                </div>
            </div>


            <div className="card-body pt-0">

                <div className="d-flex align-items-center p-3 mb-2">

                    <div className="symbol symbol-60px symbol-circle me-3">
                        <img className='image-input-wrapper w-100px h-100px' alt="Pic" src={trailAvatarImg} />

                    </div>

                    <div className="d-flex flex-column">

                        <a href="#" className="fs-4 fw-bold text-gray-900 text-hover-primary me-2">{trail.slug}</a>

                        <a href="#" className="fw-semibold text-gray-600 text-hover-primary">{trail.shortImage}</a>

                    </div>

                </div>

                <div className="fw-bold fs-5 mb-5">Descrição:</div>
                <div className="text-gray-500 fw-semibold fs-5 mb-5">{trail.description}</div>

            </div>
        </div>

    )
}

export { TrailWidget }
